@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto&display=swap');

*, *::before, *::after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.card{
    border-radius: 1rem;
    box-shadow: 4px 4px 15px rgba(#000, 0.15);
    position : relative;
    color: #434343;
}

.card .card__container{
    margin-top: -2rem;
    padding : 2rem;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    position: relative;
}

.card {
    margin-bottom: 1rem;
    font-family: 'Playfair Display', serif;
}

.card {
    font-family: 'Roboto', sans-serif;
}

/*.card::before{*/
/*    position: absolute;*/
/*    top: 2rem;*/
/*    right: 2.4rem;*/
/*    content: '';*/
/*    background: #283593;*/
/*    height: 28px;*/
/*    width: 28px;*/
/*    transform : rotate(45deg);*/
/*}*/

/*.card::after{*/
/*    position: absolute;*/
/*    content: attr(data-label);*/
/*    top: 11px;*/
/*    right: 2rem;*/
/*    padding: 0.5rem;*/
/*    width: 10rem;*/
/*    background: #3949ab;*/
/*    color: white;*/
/*    text-align: center;*/
/*    font-family: 'Roboto', sans-serif;*/
/*    box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);*/
/*}*/