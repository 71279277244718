.mainContainer {
    width: 80%;
    min-height: 0;
    max-width: 600px;
    max-height: 500px;
    padding: 20px 20px 60px;
    background-color: white;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    color: white;
    overflow-y: scroll
}

.titleContainer {
    color: black;
    margin-bottom: 20px;
    font-size: larger;
}

.contentTitle {
    font-size: large;
    align-self: center;
    margin-bottom: 15px;
    font-weight: normal;
}

.descriptionContainer {
    color: black;
    margin-bottom: 20px;
}

.commentsContainer {
    flex-direction: column;
    justify-content: space-evenly;
}

.content {
    font-weight: lighter;
    margin-left: 15px;
}

.singleComment {
    padding: 10px;
    border-radius: 0 10px 10px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    margin-bottom: 15px;
}

.singleCommentTitle {
    margin-bottom: 10px;
}

.highlightedUser {
    font-weight: normal;
}

.chatFieldArea {
    display: flex;
    height: 70px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.chatSendButton {
    background: transparent;
    margin-top: 20px;
    margin-left: -35px;
    align-self: start;
    color: black;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.chatInputField {
    max-lines: 5;
    text-underline: none;
    padding: 20px;
    width: 100%;
    resize: none;
    border-color: transparent;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}