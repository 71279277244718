.secondaryText {
    margin-top: 40px;
    color: var(--main-text-color-app);
    font-weight: normal;
    /* text-shadow: 1px 1px 2px black; */
    font-size: 24px;
    opacity: 0.5;
    width: 60%;
    align-self: center;
    text-align: center;
}