.loginContainer {
    height: 100vh;
    background-color: var(--bg-color-app);
    display: flex;
    width: 100%;
    overflow-x: hidden;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.loginFieldsContainer {
    width: 100%;
    max-width: 500px;
    background-color: var(--bg-color-app);
    border-radius: 10px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
}

.loginForm {
    width: 100%;
    align-items: center;
    align-self: center;
    flex-direction: column;
    display: flex;
    overflow-x: hidden;
    gap: 14px;
}

.login-footer-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
    flex-direction: column;
}

.login-inputs-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
}

.error-text-login {
    display: flex;
    text-align: center;
    color: red;
}

.login-footer-text-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
}

.login-footer-text-container u {
    cursor: pointer;
}

.login-footer-text-container u:hover {
    color: var(--hover-color);
}

.login-forgot-password-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
}
.login-forgot-password-container span {
    cursor: pointer;
}
.login-forgot-password-container span:hover {
    color: var(--hover-color);
}

.loginButton {
    border: 1px;
    background: var(--button-color) !important;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    color: white;
    max-width: 250px;
    font-size: 20px;
    margin-left: 20px;
    cursor: pointer;
}

.loginButton:hover {
    background: var(--button-bg-color) !important;
}

.signUpLink {
    color: var(--main-color);
    margin-bottom: 5px;
}

.signUpLink:hover {
    color: var(--hover-color);
}

.forgotPass {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    color: var(--main-color);
    text-align: center;
    pointer: pointer !important;
}

h2 {
    margin-top: 0px;
    color: var(--main-color);
}

.forgotPass:hover {
    color: var(--hover-color);
}

.textHeader {
    margin-top: 60px;
    text-align: center;
    color: #594674;
    text-decoration: underline;
    margin-bottom: 0;
    font-size: xx-large;
}


.bigCircle {
    position: absolute;
    right: -75px;
    top: 170px;
    width: 300px;
    height: 150px;
    background: rgb(64, 119, 216, 0.5);
    border-radius: 0 0 150px 150px;
    -webkit-transform: rotate(180deg);
    transform: rotate(90deg);

}

.leftCircle {
    position: absolute;
    left: 200px;
    top: 600px;
    height: 150px;
    width: 150px;
    background: linear-gradient(to right, #dc709d, #9c85e6);
    border-radius: 50%;
}

.mediumCircle {
    position: absolute;
    right: 74px;
    top: 180px;
    height: 150px;
    width: 150px;
    background: rgba(255, 178, 76, 0.5);
    border-radius: 50%;
}

.smallCircle {
    position: absolute;
    right: 120px;
    top: 380px;
    height: 50px;
    width: 50px;
    background: rgba(0, 230, 250);
    border-radius: 50%;
}

.logo {
    display: block;
    margin: 0 auto 20px;
    width: 200px;
    max-width: 300px;
}


