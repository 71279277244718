.createAccountContainer{
    width: 500px;
    background-color: #334756;
    display: flex;
    border-radius: 5px;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.platformViewItems {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

.close-button {
    background: transparent;
    border: none;
    font-size: 15px;
    margin: 15px 0;
    cursor: pointer;
}

.close-button:hover {
    color: white;
}

.singleService {
    width: 200px;
    height: 80px;
}

.createAccountForm {
    align-items: center;
    align-self: center;
    flex-direction: column;
    display: flex;
}

.comingSoonText {
    color: white;
    opacity: 0.8;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 18px;
}