.paywall-container {
    background: #ffffff;
    border-radius: 12px;
    padding: 30px;
    max-width: 500px;
    width: 100%;
    text-align: center;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paywall-header {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
}

.paywall-subheader {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 20px;
}

.paywall-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    width: 100%;
}

.paywall-button {
    background: #8800E7;
    opacity: 0.8;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 220px;
}

.paywall-button:hover {
    background: #8800E7;
    opacity: 1;
}

.paywall-button.secondary {
    background: #e0e0e0;
    color: #333333;
}

.paywall-button.secondary:hover {
    background: #d6d6d6;
}
