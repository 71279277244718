@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto&display=swap);
.loginContainer {
    height: 100vh;
    background-color: var(--bg-color-app);
    display: flex;
    width: 100%;
    overflow-x: hidden;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.loginFieldsContainer {
    width: 100%;
    max-width: 500px;
    background-color: var(--bg-color-app);
    border-radius: 10px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
}

.loginForm {
    width: 100%;
    align-items: center;
    align-self: center;
    flex-direction: column;
    display: flex;
    overflow-x: hidden;
    grid-gap: 14px;
    gap: 14px;
}

.login-footer-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    flex-direction: column;
}

.login-inputs-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 24px;
    gap: 24px;
}

.error-text-login {
    display: flex;
    text-align: center;
    color: red;
}

.login-footer-text-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 14px;
    gap: 14px;
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
}

.login-footer-text-container u {
    cursor: pointer;
}

.login-footer-text-container u:hover {
    color: var(--hover-color);
}

.login-forgot-password-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 14px;
    gap: 14px;
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
}
.login-forgot-password-container span {
    cursor: pointer;
}
.login-forgot-password-container span:hover {
    color: var(--hover-color);
}

.loginButton {
    border: 1px;
    background: var(--button-color) !important;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    color: white;
    max-width: 250px;
    font-size: 20px;
    margin-left: 20px;
    cursor: pointer;
}

.loginButton:hover {
    background: var(--button-bg-color) !important;
}

.signUpLink {
    color: var(--main-color);
    margin-bottom: 5px;
}

.signUpLink:hover {
    color: var(--hover-color);
}

.forgotPass {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    color: var(--main-color);
    text-align: center;
    pointer: pointer !important;
}

h2 {
    margin-top: 0px;
    color: var(--main-color);
}

.forgotPass:hover {
    color: var(--hover-color);
}

.textHeader {
    margin-top: 60px;
    text-align: center;
    color: #594674;
    text-decoration: underline;
    margin-bottom: 0;
    font-size: xx-large;
}


.bigCircle {
    position: absolute;
    right: -75px;
    top: 170px;
    width: 300px;
    height: 150px;
    background: rgb(64, 119, 216, 0.5);
    border-radius: 0 0 150px 150px;
    transform: rotate(90deg);

}

.leftCircle {
    position: absolute;
    left: 200px;
    top: 600px;
    height: 150px;
    width: 150px;
    background: linear-gradient(to right, #dc709d, #9c85e6);
    border-radius: 50%;
}

.mediumCircle {
    position: absolute;
    right: 74px;
    top: 180px;
    height: 150px;
    width: 150px;
    background: rgba(255, 178, 76, 0.5);
    border-radius: 50%;
}

.smallCircle {
    position: absolute;
    right: 120px;
    top: 380px;
    height: 50px;
    width: 50px;
    background: rgba(0, 230, 250);
    border-radius: 50%;
}

.logo {
    display: block;
    margin: 0 auto 20px;
    width: 200px;
    max-width: 300px;
}



.styledButton {
    align-self: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: var(--main-color) !important;
    opacity: 0.8;
    border-radius: 5px;
    border: 0;
    padding-left: 20px;
    padding-right: 20px;
    color: white !important;
}

.styledButton:hover {
    opacity: 1;
}
.loginPickerMainContainer {
    display: flex;
    background-color: #082032;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.loginPickerContainer {
    display: flex;
    width: 50%;
    background-color: #334756;
    max-width: 500px;
    height: 500px;
    max-height: 500px;
    align-self: center;
    border-radius: 5px;
    flex-direction: row;
    justify-content: space-evenly;
}

.loginPickerRight {
    justify-content: center;
    width: 100%;
    display: flex;
    border-radius: 0 5px 5px 0;
    height: 100%;
    border-color: white;
    border-width: 0 0 0 1px;
    border-style: solid;
}

.loginPickerLeft {
    border-color: white;
    border-width: 0 1px 0 0;
    border-style: solid;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 5px 0 0 5px;
}
.registrationContainer {
  height: 100vh;
  background-color: var(--bg-color-app);
  display: flex;
  justify-content: center;
  align-items: center;
}

.registrationFieldsContainer{
  width: 80%;
  max-width: 500px;
  background-color: var(--bg-color-app);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.registrationForm {
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 40px;
  align-items: center;
  align-self: center;
  flex-direction: column;
  display: flex;
}

.registration-sign-in {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 14px;
  gap: 14px;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.registration-sign-in u {
  cursor: pointer;
}

.registration-sign-in u:hover {
  color: var(--hover-color);
}

.loginField {
  width: 300px;
  color: white;
  border-color: transparent;
  border-radius: 10px;
  background-color: white;
}

.codeField {
  width: 300px;
  /* color: var(--main-color); */
  border-color: transparent;
  border-radius: 10px;
  background-color: var(--main-color);
}

.logo {
  display: block;
  margin: 0 auto 20px;
  width: 200px;
  max-width: 300px;
}

  .textLogoImg {
    width: 100px;
    height: 80px;
    position: absolute;
    left: 70px;
    top: 0;
  }
html {
  /* filter: invert(100%); */
  /* font-size: 100%; */
  background-color: var(--bg-color-app);
  color: black;
}

html.dark-mode {
  filter: invert(100%);
  background-color: #09d0ff;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}

:root {
  --feature-badge: #00C9A7;
  --bg-color-app: #24272B;
  --bg-color-landing-page: white;
  --main-text-color-app: #121212;
  --main-text-color-lading-page: white;
  --main-color: #8800E7;
  --hover-color: #8800E7;
  --button-color: linear-gradient(to right, #dc709d, #9c85e6);
  --button-bg-color: linear-gradient(to left, #dc709d, #9c85e6);
}

.homeMainContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    background-color: var(--bg-color-app);
    min-height: 100vh;
}

.chainContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.chainConnectionContainer {
    margin-top: 40px;
    min-height: 100px;
    padding-left: 20px;
    padding-right: 20px;
    width: 80%;
    background-color: transparent;
    justify-content: start;
    border-top: white;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
}

.chainLinkPlaceholder {
    display: inline-block;
    width: 75px;
    height: 75px;
    border-radius: 3px;
    border-color: white;
    background-color: white;
}

.chainLinkContainer {
    margin-left: 20px;
    display: inline-block;
    background-color: transparent;
}

.singleAction {
    width: 14px;
    height: 14px;
    margin-left: 3px;
    color: #082032;
}

.createAccountContainer{
    width: 500px;
    background-color: #334756;
    display: flex;
    border-radius: 5px;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.platformViewItems {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    grid-gap: 15px;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

.close-button {
    background: transparent;
    border: none;
    font-size: 15px;
    margin: 15px 0;
    cursor: pointer;
}

.close-button:hover {
    color: white;
}

.singleService {
    width: 200px;
    height: 80px;
}

.createAccountForm {
    align-items: center;
    align-self: center;
    flex-direction: column;
    display: flex;
}

.comingSoonText {
    color: white;
    opacity: 0.8;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 18px;
}
.addProjectsMainContainer {
    width: 500px;
    padding-bottom: 20px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-color-app);
    border-radius: 5px;
}
.addProjectsOptionsContainer {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.addProjectsOption {
    padding: 10px;
    width: 45%;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    border: 1px solid var(--bg-color-app);
}

.addProjectsOption:hover {
    padding: 10px;
    border-radius: 5px;
    justify-content: center;
    border: 2px solid white;
    cursor: pointer;
}

.close-button {
    background: transparent;
    border: none;
    font-size: 15px;
    margin: 15px 0;
    cursor: pointer;
}

.close-button:hover {
    color: white;
}
.projectsMainContainer {
    width: 40%;
    max-height: 70%;
    overflow: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #334756;
    border-radius: 5px;
}

.projectsContentContainer  {
    width: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.buttonArea  {
    display: flex;
    height: 100px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.progressBarArea  {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.projectsContentTitleContainer {
    display: flex;
    margin-left: 20px;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.projectsContainer {
    background-color: #475967;
    border-radius: 0 0 5px 5px;
    padding-bottom: 20px;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.projectsContainerItems {
    border: 1px solid #FFFFFFCC;
    border-right-width: 0;
    border-left-width: 0;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    overflow: auto;
}

.projectItem {
    display: inline-block;
}


.modularProgressbarMainContainer {
    display: flex;
    border-radius: 1000px;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 50%;
    max-width: 500px;
    height: 500px;
}
.mainContentContainer {
    margin-top: 40px;
    width: 80%;
}

.taskPrioritisationContainer {
    display: flex;
    width: 90%;
    flex-direction: row;
    justify-content: flex-end;
    height: 50px;
}

.taskPrioritisationContainerMobile {
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    flex-direction: row;
    justify-content: space-evenly;
    height: 50px;
}

.taskPrioritisationContainerLeft {
    display: flex;
    align-items: center;
    justify-content: center;
}

.taskPrioritisationContainerRight {
    display: flex;
    align-items: center;
    justify-content: center;
}

.priorityButton {
    color: #e4e4e4;
    opacity: 0.4;
}

.priorityButtonSelected {
    color: #e4e4e4;
}

.tasksMainContainer {
    width: 100%;
    margin-bottom: 400px;
    min-height: 100px;
    max-height: 500px;
    border-radius: 5px;
    /*overflow-y: auto;*/
    display: block;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-bottom: 10px;
}

.noTasksContainer {
    margin-top: 40px;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.noTasksText {
    opacity: 0.8;
    justify-self: center;
    color: white;
    font-weight: normal;
    font-size: 18px;
}

.singleTaskTitleWeb {
    margin-top: 22px;
    margin-right: 10px;
    height: 80px;
    color: #e4e4e4;
    font-size: large;
    width: 80%;
    text-align: start;
}

.singleTaskTitleMobile {
    margin-top: 22px;
    margin-right: 10px;
    height: 80px;
    color: #e4e4e4;
    font-size: large;
    text-align: start;
}

.singleTaskContainer {
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 100%;
    justify-content: center;
    border-radius: 10px;
    margin-top: 10px;
}

.taskEstimationContainerWeb {
    display: flex;
    margin-left: 15px;
    justify-content: start;
    align-self: center;
    align-items: center;
}
.taskTimeTrackerContainer {
    display: flex;
    margin-left: 10px;
    justify-content: start;
    align-self: center;
    align-items: center;
}

.singleTaskWeb {
    position: relative;
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 90%;
    justify-content: space-between;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #4A525A;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
}

.actionContainerWeb {
    width: 290px;
    min-width: 290px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.issueCommunicationButtonWeb {
    align-self: center;
    width: 25px;
    color: #9f9f9f;
    opacity: 0.9;
    height: 25px;
}

.issueCommunicationButtonWeb:hover {
    align-self: center;
    width: 26px;
    height: 26px;
    color: #9f9f9f;
    opacity: 1;
}

.goToTicketButton {
    align-self: center;
    width: 25px;
    height: 25px;
    color: #9f9f9f;
    opacity: 0.9;
    margin-left: 10px;
    cursor: pointer;
}

.goToTicketButton:hover {
    align-self: center;
    width: 26px;
    height: 26px;
    margin-left: 10px;
    color: #9f9f9f;
    opacity: 1;
    cursor: pointer;
}

.issueCommunicationButtonContainerWeb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-top: 26px;
}

.issueCommunicationButtonBadgeWeb {
    align-self: end;
    color: #5bb381;
    padding: 0;
    font-size: small;
    font-weight: bold;
}

.issueCommunicationButtonBadgePlaceholderWeb {
    height: 19px;
}

.issueCommunicationButtonBadgeWeb:hover {
    align-self: end;
    padding: 0;
    font-size: small;
    font-weight: bold;
}

.divider {
    background-color: black;
    opacity: 0.5;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 60%;
    align-self: center;
    height: 1px;
}

.main {
    align-self: center;
    width: 200px;
    margin-right: 10px;
    margin-left: 10px;
}

.select {
    font-size: xx-small;
    background-color: whitesmoke;
    height: 30px;
}
.taskMobileMainContainer {
    align-self: center;
    display: flex;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.taskEstimationContainerMobile {
    width: 60px;
    margin-left: 15px;
    justify-content: start;
    display: flex;
    align-self: center;
    align-items: center;
}

.taskMobileContainer {
    align-self: center;
    display: flex;
    padding: 10px;
    background-color: #334756;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    flex-direction: column;
    justify-content: start;
}

.taskMobileTopContainer {
    width: 80%;
    text-align: left;
    display: flex;
    justify-content: start;
    flex-direction: row;
}

.taskMobileBottomContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.taskMobileBottomContainerLeft {

}

.taskMobileBottomContainerMiddle {

}

.taskMobileBottomContainerRight {

}


.projectSelectorMainContainer {
    width: 50%;
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: #334756;
    border-radius: 5px;
}

.projectSelectorItemsContainer {
    width: 100%;
    height: 300px;
    border: 1px solid #FFFFFFCC;
    border-right-width: 0;
    border-left-width: 0;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    overflow: auto;
}

.projectItem {
    margin: 25px;
    display: inline-block;
}

.projectSelectorTitle {
    margin-bottom: 40px;
    color: white;
    opacity: 0.9;
    font-size: 21px;
}

.mainContainer {
    width: 80%;
    min-height: 0;
    max-width: 600px;
    max-height: 500px;
    padding: 20px 20px 60px;
    background-color: white;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    color: white;
    overflow-y: scroll
}

.titleContainer {
    color: black;
    margin-bottom: 20px;
    font-size: larger;
}

.contentTitle {
    font-size: large;
    align-self: center;
    margin-bottom: 15px;
    font-weight: normal;
}

.descriptionContainer {
    color: black;
    margin-bottom: 20px;
}

.commentsContainer {
    flex-direction: column;
    justify-content: space-evenly;
}

.content {
    font-weight: lighter;
    margin-left: 15px;
}

.singleComment {
    padding: 10px;
    border-radius: 0 10px 10px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    margin-bottom: 15px;
}

.singleCommentTitle {
    margin-bottom: 10px;
}

.highlightedUser {
    font-weight: normal;
}

.chatFieldArea {
    display: flex;
    height: 70px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.chatSendButton {
    background: transparent;
    margin-top: 20px;
    margin-left: -35px;
    align-self: start;
    color: black;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.chatInputField {
    max-lines: 5;
    text-underline: none;
    padding: 20px;
    width: 100%;
    resize: none;
    border-color: transparent;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.headerContainer {
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  justify-content: end;
  height: 60px;
  margin-right: 40px;
  background: #4A525A;
  margin-left: 40px;
  margin-top: 40px;
  border-radius: 20px;
  /*padding-left: 20px;*/
  padding-right: 20px;
}

.optionsContainer {
  font-size: medium;
  font-weight: lighter;
  color: whitesmoke;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.optionsContainer:hover {
  font-weight: normal;
}

.backToHomePageIcon {
  color: white;
  opacity: 0.4;
  align-self: center;
  margin-left: 20px;
}

.backToHomePageIcon:hover {
  color: white;
  opacity: 0.8;
  align-self: center;
  margin-left: 20px;
}

.usersMainContainer {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: start;
}

.chainLinkContainer {
  margin-left: 20px;
  display: inline-block;
  background-color: transparent;
}

.header-users-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.container {
  display: flex;
  border: 1px solid white;
  flex-direction: column;
  align-items: stretch;
  width: 700px;
  margin: 40px auto 0;
  background-color: var(--bg-color-app);
  border-radius: 40px;
  padding: 20px;
  box-shadow: 0px 2px 10px var(--main-color);
}

.chip {
  margin-left: 30px;

  background-color: #ff9171 !important;
}

.memberContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  space: 100px;
  color: gray;
}
.memberName {
  font-size: 1rem;
  line-height: 1.5rem;
  width: 300px;
  color: #3160e1 !important;
}

.usersTopContainer {
  background-color: green;
  height: 100px;
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
}

.usersTopContainerLeft {
  display: flex;
  flex-direction: row;
}

.usersTopContainerRight {
  display: flex;
  flex-direction: row;
}

.usersBottomContainer {
  display: flex;
  flex-direction: column;
}

.usersBottomContainerTop {
  display: flex;
  flex-direction: row;
}

.usersBottomContainerBottom {
  display: flex;
  flex-direction: row;
}

.usersModalContainer {
  width: 50%;
  max-width: 500px;
  border-radius: 5px;
  background-color: #334756;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.invite-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .invite-input {
    border: none;
    transition: border-color 0.3s ease-in-out;
    display: flex;
    height: 36px;
    padding: 8px 16px;
    margin-left: 26px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.40);
    color: white;
  }
  
  .invite-button {
    display: flex;
    cursor: pointer;
    height: 36px;
    padding: 9px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: none;
    color: #FFFFFF;
    background: #3160e1;
  }

  .invite-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
  }

  .access-level-dropdown {
    height: 36px;
    padding: 10px;
    border-radius: 20px;
    border-color: black;
    font-size: xx-small;
    background-color: whitesmoke;
  }
  
  .invite-input-container {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }
  
  .invite-input-container input {
    border: none;
    border-bottom: 2px
  }  
.invitationsMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 80%;
    max-width: 800px;
    min-width: 320px;
    border-radius: 5px;
    height: 80%;
    max-height: 300px;
    background-color: #334756;
}

.invitationsContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    justify-content: start;
    height: 100%;
    width: 100%;
}

.invitationsHorizontalContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.invitationsVerticalContainer {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
}
.paywall-container {
    background: #ffffff;
    border-radius: 12px;
    padding: 30px;
    max-width: 500px;
    width: 100%;
    text-align: center;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paywall-header {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
}

.paywall-subheader {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 20px;
}

.paywall-actions {
    display: flex;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 20px;
    width: 100%;
}

.paywall-button {
    background: #8800E7;
    opacity: 0.8;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 220px;
}

.paywall-button:hover {
    background: #8800E7;
    opacity: 1;
}

.paywall-button.secondary {
    background: #e0e0e0;
    color: #333333;
}

.paywall-button.secondary:hover {
    background: #d6d6d6;
}

.pricing-plan-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.pricing-plan-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.pricing-plan-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.pricing-header h3 {
    font-size: 1.5rem;
    margin: 0;
    color: #333333;
}

.pricing-header p {
    font-size: 1rem;
    margin: 10px 0;
    color: #666666;
}

.pricing-details {
    margin: 20px 0;
}

.pricing-amount {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-weight: bold;
}

.currency {
    font-size: 1.2rem;
    color: #333333;
}

.amount {
    font-size: 3rem;
    color: #8800E7;
    margin: 0 5px;
}

.frequency {
    font-size: 1rem;
    color: #666666;
}

.features-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.features-list li {
    font-size: 1rem;
    margin: 10px 0;
    color: #555555;
}

.cta-button {
    background: #8800E7;
    opacity: 0.8;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.cta-button:hover {
    background: #8800E7;
    opacity: 1;
}
*, *::before, *::after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.card{
    border-radius: 1rem;
    box-shadow: 4px 4px 15px rgba(#000, 0.15);
    position : relative;
    color: #434343;
}

.card .card__container{
    margin-top: -2rem;
    padding : 2rem;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    position: relative;
}

.card {
    margin-bottom: 1rem;
    font-family: 'Playfair Display', serif;
}

.card {
    font-family: 'Roboto', sans-serif;
}

/*.card::before{*/
/*    position: absolute;*/
/*    top: 2rem;*/
/*    right: 2.4rem;*/
/*    content: '';*/
/*    background: #283593;*/
/*    height: 28px;*/
/*    width: 28px;*/
/*    transform : rotate(45deg);*/
/*}*/

/*.card::after{*/
/*    position: absolute;*/
/*    content: attr(data-label);*/
/*    top: 11px;*/
/*    right: 2rem;*/
/*    padding: 0.5rem;*/
/*    width: 10rem;*/
/*    background: #3949ab;*/
/*    color: white;*/
/*    text-align: center;*/
/*    font-family: 'Roboto', sans-serif;*/
/*    box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);*/
/*}*/
@media (min-width: 1024px) {
    .homePageHeaderContainer {
        padding: 40px;
    }
}

@media (max-width: 768px) {
    .homePageHeaderContainer {
        padding: 10px;
    }
}

.homePageHeaderContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    background-color: var(--bg-color-landing-page);
    color: #e9ecef;
}

.left-side-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.homePageStatus {
    background-color: var(--main-color);
    border-radius: 10px;
    border-color: white;
    font-size: small;
    padding: 5px 10px;
    font-weight: normal;
}

.homePageStatus:hover {
    background-color: var(--hover-color);
    border-radius: 10px;
    border-color: white;
    font-size: small;
    padding: 5px 10px;
    font-weight: bold;
}

.homePageOptionText {
    color: var(--main-color);
    margin-right: 15px;
    font-weight: lighter;
}

.homePageOptionText:hover {
    color: var(--hover-color);
    margin-right: 15px;
    font-weight: normal;
}

.homePageOptionTextSelected {
    color: var(--main-color);
    margin-right: 15px;
    font-weight: normal;
}

.homePageOptionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.integrationsMenu {
    color: var(--main-color);
}

.headerMenuContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.item {
    align-self: center;
    position: relative;
    display: inline-block;
}

.notifyBadge {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    background: var(--main-color);
    text-align: center;
    color: white;
    padding: 1px 3px;
    font-size: 8px;
}

@media only screen and (max-width: 600px) {
    .item {
        display: block;
        text-align: center;
        width: 100px;
    }

}
.homePageMainContainer {
    background: var(--bg-color-landing-page);
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.homePagePricingContainer {
    padding-bottom: 100px;
    margin-top: 10px;
    align-self: center;
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.homePageHomeContainer {
    width: 100%;
    padding-bottom: 280px;
    margin-top: 100px;
    align-self: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.headlineText {
    margin: 0;
    color: whitesmoke;
    line-height: 80px;
    font-weight: bold;
    font-size: 72px;
    width: 100%;
    align-self: center;
}

.copyOne {
    margin-top: 40px;
    color: whitesmoke;
    font-weight: normal;
    font-size: 24px;
    opacity: 0.5;
    width: 60%;
    align-self: center;
}

.introduction-page-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
    width: 100%;
}

.introduction-page-header-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}

/*.introduction-chip-header {*/
/*    padding: 5px 10px;*/
/*    width: 100px;*/
/*    color: white;*/
/*    background-color: var(--main-color);*/
/*    opacity: 0.8;*/
/*    border-radius: 100px;*/
/*}*/
.xlens-intro-gif{
    width: 100%;
}

.get-started-button {
    margin-top: 1.5rem;
    background-color: #ffffff;
    text-transform: uppercase;
    color: var(--main-color);
    font-weight: 700;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}


.get-started-button:hover {
    background-color: #fafafa;
    color: var(--hover-color);
}

.get-started-button-bottom {
    margin-top: 1.5rem;
    background-color: var(--main-color);
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 700;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.get-started-button-bottom:hover {
    background-color: var(--hover-color) !important;
    color: #fafafa !important;
}

.introduction-page-header-container {
    padding: 20px;
    width: 100%;
    background-color: var(--main-color);
}

@media (min-width: 1024px) {
    .introduction-page-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 30px;
        gap: 30px;
        flex-wrap: wrap;
    }


    .introduction-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-gap: 15px;
        gap: 15px;
        flex-wrap: wrap;

    }

    .xlens-intro-gif{
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        max-width: 1000px;
        max-height: 1000px;
        margin: 20px
    }


    .introduction-text-header {
        color: white;
        font-size: 52px;
    }

    .introduction-header-info-text{
        font-size: 24px;
        color: white;
        text-align: center;
    }

    .introduction-platforms {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 80px;
        gap: 80px;
        margin-bottom: 120px;
        flex-wrap: wrap;
    }

    .introduction-page-body-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 80px;
        gap: 80px;
        margin-bottom: 120px;
        flex-wrap: wrap;
    }
    .body-container-first {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        background-color: #fafafa;
        padding: 40px;
    }
    .body-container-second {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        background-color: #FFFFFF;
        padding: 40px;
    }
    .body-container-third {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        background-color: #fafafa;
        padding: 40px;
    }
}

@media (max-width: 768px) {
    .introduction-platforms,
    .introduction-page-body-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        margin-bottom: 10px;
        grid-gap: 15px;
        gap: 15px;
    }

    .body-container-first,
    .body-container-second,
    .body-container-third {
        display: flex;
        flex-direction: column;
        grid-gap: 15px;
        gap: 15px;
    }

    .introduction-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 15px;
        gap: 15px;
        width: 100%; /* Ensure the header occupies full width */
        text-align: center; /* Center align the content */
    }

    .introduction-text-header {
        color: white;
        font-size: 32px; /* Reduce font size for smaller screens */
    }

    .introduction-chip-header {
        font-size: 18px; /* Increase font size for better visibility */
    }

    .headlineText {
        font-size: 48px; /* Smaller font size for headlines */
        line-height: 60px; /* Adjust line height for readability */
    }

    .copyOne {
        font-size: 18px; /* Smaller font size for copy */
        width: 80%; /* Increase width to use more of the small screen space */
        text-align: center; /* Center align the text */
    }

    /* Ensure image and video elements are responsive */
    img, video {
        width: 100%; /* Make images and videos take the full width */
        max-width: 100%; /* Ensure images and videos do not exceed their container's width */
        height: auto; /* Adjust height automatically to maintain aspect ratio */
    }

    /* Adjust the layout for features with images and text */
    .TextWithImage {
        flex-direction: column; /* Stack image and text vertically */
    }

    /* Adjust button sizes if necessary */
    .get-started-button,
    .get-started-button-bottom {
        padding: 15px 30px; /* Adjust padding for a better fit on small screens */
        font-size: 16px; /* Adjust font size for readability */
        width: auto; /* Allow button width to adjust based on content */
    }
}

.secondaryText {
    margin-top: 40px;
    color: var(--main-text-color-app);
    font-weight: normal;
    /* text-shadow: 1px 1px 2px black; */
    font-size: 24px;
    opacity: 0.5;
    width: 60%;
    align-self: center;
    text-align: center;
}
.demoRegionMainContainer {
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.demoChainContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.demoChainConnectionContainer {
    min-height: 100px;
    padding-left: 20px;
    padding-right: 20px;
    width: 80%;
    background-color: transparent;
    justify-content: start;
    border-top: white;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    margin-top: 10px;
}

.demoChainLinkContainer {

    display: inline-block;
    border-radius: 3px;
    border-color: whitesmoke;
    background-color: transparent;
}
.demoDividerContainer {
    margin-bottom: 80px;
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    align-content: center;
    align-items: center;
}

.demoDividerLine {
    opacity: 0.2;
    width: 20%;
    align-self: center;
    height: 1px;
    background-color: white;
}

.demoDividerText {
    opacity: 0.7;
    align-self: flex-start;
    font-weight: normal;
    color: white;
}
.legalContentContainer {
    width: 100%;
}
.textWithImageContainer {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

@media (min-width: 1024px) {
    .textWithImageContainer {
        width: 45%;
        margin: 10px;
    }
}

.textImageCollection {
    display: grid;
    flex-direction: row;
    justify-content: space-around;
    justify-items: center;
    flex-wrap: nowrap;
    margin: 20px;
    align-items: center;
    grid-column-gap: 36px;
}

.textContainer{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    justify-items: start;
}

.textContainer p {
    padding-right: 10px;
    margin-bottom: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.image-style {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    max-width: 100%;
    height: 300px;
    object-fit: cover;
}

.videoButtonContainer {
  display: flex;
  align-items: center;
}

.videoButton {
  background-color: white;
  border: 2px solid var(--main-color);
  color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
}

.videoButton:hover {
  background-color: var(--hover-color);
  border: 2px solid var(--hover-color);
  color: white;
}

.videoButtonText {
  font-size: 16px;
  color: var(--main-color);
}

button .MuiSvgIcon-root {
  fill: var(--main-color);
}

.headerText {
  color: var(--main-text-color-app);
  text-shadow: black 1px 1px 2px;
  opacity: 0.8;
  margin: 10px 10px 30px;
  font-style: italic;
  font-weight: bold;
  font-size: 65px;
  width: 100%;
  align-self: center;
  text-align: center;
}


.videoModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.videoModalContent {
  position: relative;
  width: 80%;
  height: 80%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.videoPlayer {
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  .videoButtonContainer {
    flex-direction: column;
    align-items: center;
  }

  .videoButton {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }

  .videoButtonText {
    font-size: 14px;
    text-align: center;
  }

  .videoModalContent {
    width: 90%;
    height: 90%;
  }

  .closeButton {
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    font-size: 16px;
  }
}
/* App.css or BlogPostGrid.css */
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    grid-gap: 16px;
    padding: 20px;
}

.card {
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}
/* BlogPostCard.css */
.blog-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
    margin: 20px;
    line-height: 1.6;
    color: #333;
    transition: box-shadow 0.3s ease;
}

.blog-card:hover {
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.title {
    color: #333;
    font-size: 24px;
    margin-bottom: 5px;
}

.metadata, .author, .date-published {
    font-size: 14px;
    color: #666;
    font-style: italic;
}

.category {
    font-size: 16px;
    color: var(--main-color);
    margin: 10px 0;
}

.tags .tag {
    background: #f1f1f1;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 5px;
    font-size: 12px;
}

.section h4 {
    margin-top: 20px;
    color: #333;
    font-size: 20px;
}

.introduction, .conclusion {
    margin-top: 10px;
}

.social-links a {
    margin-right: 10px;
    color: #3b5998;
    text-decoration: none;
}

.social-links a:hover {
    text-decoration: underline;
}

.cover-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
}
/* Base styling for the blog post */
.blog-post {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Georgia', serif;
    line-height: 1.6;
    color: #333;
}

/* Fullscreen and responsive layout */
body, html {
    width: 100%;
    margin: 0;
    padding: 0;
    background: #f4f4f4;
}

/* Cover image */
.blog-post img:first-of-type {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    margin-bottom: 20px;
}

/* Metadata styling */
.blog-meta {
    font-size: 0.85rem;
    color: #666;
    margin-bottom: 10px;
}

/* Category and tags */
.blog-post h2 {
    color: var(--main-text-color-app);
}

/* Headings within the content */
.blog-post h3 {
    color: #2a2f2a;
}

/* Social media links */
.social-links a {
    text-decoration: none;
    color: var(--main-text-color-app);
    margin-right: 10px;
}

.social-links a:hover {
    text-decoration: underline;
}

.blog-section-container {
    margin-top: 40px;
}

.blog-section {
    margin-top: 20px;
}

.back-button {
    z-index: 10;
    position: absolute;
    top: 100px;
    left: 20px;
    padding: 10px;
    font-size: 1em;
    cursor: pointer;
    color: #333;
    background-color: #f4f4f4;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.back-button:hover {
    background-color: #e2e2e2;
}

.blog-conclusion {
    margin-top: 40px;
}

/* Responsive design */
@media (max-width: 768px) {
    .blog-post {
        padding: 10px;
    }

    .blog-post img:first-of-type {
        max-height: 300px;
    }
}

