.main {
    align-self: center;
    width: 200px;
    margin-right: 10px;
    margin-left: 10px;
}

.select {
    font-size: xx-small;
    background-color: whitesmoke;
    height: 30px;
}