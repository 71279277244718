.textWithImageContainer {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

@media (min-width: 1024px) {
    .textWithImageContainer {
        width: 45%;
        margin: 10px;
    }
}

.textImageCollection {
    display: -ms-grid;
    display: grid;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    justify-items: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 36px;
}

.textContainer{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    justify-items: start;
}

.textContainer p {
    padding-right: 10px;
    margin-bottom: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.image-style {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    max-width: 100%;
    height: 300px;
    object-fit: cover;
}
