@import "../../index.css";

.headerContainer {
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  justify-content: end;
  height: 60px;
  margin-right: 40px;
  background: #4A525A;
  margin-left: 40px;
  margin-top: 40px;
  border-radius: 20px;
  /*padding-left: 20px;*/
  padding-right: 20px;
}

.optionsContainer {
  font-size: medium;
  font-weight: lighter;
  color: whitesmoke;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.optionsContainer:hover {
  font-weight: normal;
}

.backToHomePageIcon {
  color: white;
  opacity: 0.4;
  align-self: center;
  margin-left: 20px;
}

.backToHomePageIcon:hover {
  color: white;
  opacity: 0.8;
  align-self: center;
  margin-left: 20px;
}
