@import "../../index.css";

.homeMainContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    background-color: var(--bg-color-app);
    min-height: 100vh;
}
