.modularProgressbarMainContainer {
    display: flex;
    border-radius: 1000px;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 50%;
    max-width: 500px;
    height: 500px;
}