@import "../../index.css";

.usersMainContainer {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: start;
}

.chainLinkContainer {
  margin-left: 20px;
  display: inline-block;
  background-color: transparent;
}

.header-users-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.container {
  display: flex;
  border: 1px solid white;
  flex-direction: column;
  align-items: stretch;
  width: 700px;
  margin: 40px auto 0;
  background-color: var(--bg-color-app);
  border-radius: 40px;
  padding: 20px;
  box-shadow: 0px 2px 10px var(--main-color);
}

.chip {
  margin-left: 30px;

  background-color: #ff9171 !important;
}

.memberContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  space: 100px;
  color: gray;
}
.memberName {
  font-size: 1rem;
  line-height: 1.5rem;
  width: 300px;
  color: #3160e1 !important;
}

.usersTopContainer {
  background-color: green;
  height: 100px;
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
}

.usersTopContainerLeft {
  display: flex;
  flex-direction: row;
}

.usersTopContainerRight {
  display: flex;
  flex-direction: row;
}

.usersBottomContainer {
  display: flex;
  flex-direction: column;
}

.usersBottomContainerTop {
  display: flex;
  flex-direction: row;
}

.usersBottomContainerBottom {
  display: flex;
  flex-direction: row;
}

.usersModalContainer {
  width: 50%;
  max-width: 500px;
  border-radius: 5px;
  background-color: #334756;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
