.demoDividerContainer {
    margin-bottom: 80px;
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    align-content: center;
    align-items: center;
}

.demoDividerLine {
    opacity: 0.2;
    width: 20%;
    align-self: center;
    height: 1px;
    background-color: white;
}

.demoDividerText {
    opacity: 0.7;
    align-self: flex-start;
    font-weight: normal;
    color: white;
}