.pricing-plan-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.pricing-plan-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.pricing-plan-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.pricing-header h3 {
    font-size: 1.5rem;
    margin: 0;
    color: #333333;
}

.pricing-header p {
    font-size: 1rem;
    margin: 10px 0;
    color: #666666;
}

.pricing-details {
    margin: 20px 0;
}

.pricing-amount {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-weight: bold;
}

.currency {
    font-size: 1.2rem;
    color: #333333;
}

.amount {
    font-size: 3rem;
    color: #8800E7;
    margin: 0 5px;
}

.frequency {
    font-size: 1rem;
    color: #666666;
}

.features-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.features-list li {
    font-size: 1rem;
    margin: 10px 0;
    color: #555555;
}

.cta-button {
    background: #8800E7;
    opacity: 0.8;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.cta-button:hover {
    background: #8800E7;
    opacity: 1;
}