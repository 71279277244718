.styledButton {
    align-self: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: var(--main-color) !important;
    opacity: 0.8;
    border-radius: 5px;
    border: 0;
    padding-left: 20px;
    padding-right: 20px;
    color: white !important;
}

.styledButton:hover {
    opacity: 1;
}