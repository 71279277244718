.item {
    align-self: center;
    position: relative;
    display: inline-block;
}

.notifyBadge {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    background: var(--main-color);
    text-align: center;
    color: white;
    padding: 1px 3px;
    font-size: 8px;
}

@media only screen and (max-width: 600px) {
    .item {
        display: block;
        text-align: center;
        width: 100px;
    }

}