.invitationsMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 80%;
    max-width: 800px;
    min-width: 320px;
    border-radius: 5px;
    height: 80%;
    max-height: 300px;
    background-color: #334756;
}

.invitationsContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    justify-content: start;
    height: 100%;
    width: 100%;
}

.invitationsHorizontalContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.invitationsVerticalContainer {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
}