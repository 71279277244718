.homePageMainContainer {
    background: var(--bg-color-landing-page);
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.homePagePricingContainer {
    padding-bottom: 100px;
    margin-top: 10px;
    align-self: center;
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.homePageHomeContainer {
    width: 100%;
    padding-bottom: 280px;
    margin-top: 100px;
    align-self: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.headlineText {
    margin: 0;
    color: whitesmoke;
    line-height: 80px;
    font-weight: bold;
    font-size: 72px;
    width: 100%;
    align-self: center;
}

.copyOne {
    margin-top: 40px;
    color: whitesmoke;
    font-weight: normal;
    font-size: 24px;
    opacity: 0.5;
    width: 60%;
    align-self: center;
}

.introduction-page-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
}

.introduction-page-header-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

/*.introduction-chip-header {*/
/*    padding: 5px 10px;*/
/*    width: 100px;*/
/*    color: white;*/
/*    background-color: var(--main-color);*/
/*    opacity: 0.8;*/
/*    border-radius: 100px;*/
/*}*/
.xlens-intro-gif{
    width: 100%;
}

.get-started-button {
    margin-top: 1.5rem;
    background-color: #ffffff;
    text-transform: uppercase;
    color: var(--main-color);
    font-weight: 700;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}


.get-started-button:hover {
    background-color: #fafafa;
    color: var(--hover-color);
}

.get-started-button-bottom {
    margin-top: 1.5rem;
    background-color: var(--main-color);
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 700;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.get-started-button-bottom:hover {
    background-color: var(--hover-color) !important;
    color: #fafafa !important;
}

.introduction-page-header-container {
    padding: 20px;
    width: 100%;
    background-color: var(--main-color);
}

@media (min-width: 1024px) {
    .introduction-page-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
        flex-wrap: wrap;
    }


    .introduction-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;

    }

    .xlens-intro-gif{
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        max-width: 1000px;
        max-height: 1000px;
        margin: 20px
    }


    .introduction-text-header {
        color: white;
        font-size: 52px;
    }

    .introduction-header-info-text{
        font-size: 24px;
        color: white;
        text-align: center;
    }

    .introduction-platforms {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 80px;
        margin-bottom: 120px;
        flex-wrap: wrap;
    }

    .introduction-page-body-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 80px;
        margin-bottom: 120px;
        flex-wrap: wrap;
    }
    .body-container-first {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        background-color: #fafafa;
        padding: 40px;
    }
    .body-container-second {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        background-color: #FFFFFF;
        padding: 40px;
    }
    .body-container-third {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        background-color: #fafafa;
        padding: 40px;
    }
}

@media (max-width: 768px) {
    .introduction-platforms,
    .introduction-page-body-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        margin-bottom: 10px;
        gap: 15px;
    }

    .body-container-first,
    .body-container-second,
    .body-container-third {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .introduction-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        width: 100%; /* Ensure the header occupies full width */
        text-align: center; /* Center align the content */
    }

    .introduction-text-header {
        color: white;
        font-size: 32px; /* Reduce font size for smaller screens */
    }

    .introduction-chip-header {
        font-size: 18px; /* Increase font size for better visibility */
    }

    .headlineText {
        font-size: 48px; /* Smaller font size for headlines */
        line-height: 60px; /* Adjust line height for readability */
    }

    .copyOne {
        font-size: 18px; /* Smaller font size for copy */
        width: 80%; /* Increase width to use more of the small screen space */
        text-align: center; /* Center align the text */
    }

    /* Ensure image and video elements are responsive */
    img, video {
        width: 100%; /* Make images and videos take the full width */
        max-width: 100%; /* Ensure images and videos do not exceed their container's width */
        height: auto; /* Adjust height automatically to maintain aspect ratio */
    }

    /* Adjust the layout for features with images and text */
    .TextWithImage {
        flex-direction: column; /* Stack image and text vertically */
    }

    /* Adjust button sizes if necessary */
    .get-started-button,
    .get-started-button-bottom {
        padding: 15px 30px; /* Adjust padding for a better fit on small screens */
        font-size: 16px; /* Adjust font size for readability */
        width: auto; /* Allow button width to adjust based on content */
    }
}
