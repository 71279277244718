.chainContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.chainConnectionContainer {
    margin-top: 40px;
    min-height: 100px;
    padding-left: 20px;
    padding-right: 20px;
    width: 80%;
    background-color: transparent;
    justify-content: start;
    border-top: white;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
}

.chainLinkPlaceholder {
    display: inline-block;
    width: 75px;
    height: 75px;
    border-radius: 3px;
    border-color: white;
    background-color: white;
}

.chainLinkContainer {
    margin-left: 20px;
    display: inline-block;
    background-color: transparent;
}

.singleAction {
    width: 14px;
    height: 14px;
    margin-left: 3px;
    color: #082032;
}
