.mainContentContainer {
    margin-top: 40px;
    width: 80%;
}

.taskPrioritisationContainer {
    display: flex;
    width: 90%;
    flex-direction: row;
    justify-content: flex-end;
    height: 50px;
}

.taskPrioritisationContainerMobile {
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    flex-direction: row;
    justify-content: space-evenly;
    height: 50px;
}

.taskPrioritisationContainerLeft {
    display: flex;
    align-items: center;
    justify-content: center;
}

.taskPrioritisationContainerRight {
    display: flex;
    align-items: center;
    justify-content: center;
}

.priorityButton {
    color: #e4e4e4;
    opacity: 0.4;
}

.priorityButtonSelected {
    color: #e4e4e4;
}

.tasksMainContainer {
    width: 100%;
    margin-bottom: 400px;
    min-height: 100px;
    max-height: 500px;
    border-radius: 5px;
    /*overflow-y: auto;*/
    display: block;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-bottom: 10px;
}

.noTasksContainer {
    margin-top: 40px;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.noTasksText {
    opacity: 0.8;
    justify-self: center;
    color: white;
    font-weight: normal;
    font-size: 18px;
}

.singleTaskTitleWeb {
    margin-top: 22px;
    margin-right: 10px;
    height: 80px;
    color: #e4e4e4;
    font-size: large;
    width: 80%;
    text-align: start;
}

.singleTaskTitleMobile {
    margin-top: 22px;
    margin-right: 10px;
    height: 80px;
    color: #e4e4e4;
    font-size: large;
    text-align: start;
}

.singleTaskContainer {
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 100%;
    justify-content: center;
    border-radius: 10px;
    margin-top: 10px;
}

.taskEstimationContainerWeb {
    display: flex;
    margin-left: 15px;
    justify-content: start;
    align-self: center;
    align-items: center;
}
.taskTimeTrackerContainer {
    display: flex;
    margin-left: 10px;
    justify-content: start;
    align-self: center;
    align-items: center;
}

.singleTaskWeb {
    position: relative;
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 90%;
    justify-content: space-between;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #4A525A;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
}

.actionContainerWeb {
    width: 290px;
    min-width: 290px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.issueCommunicationButtonWeb {
    align-self: center;
    width: 25px;
    color: #9f9f9f;
    opacity: 0.9;
    height: 25px;
}

.issueCommunicationButtonWeb:hover {
    align-self: center;
    width: 26px;
    height: 26px;
    color: #9f9f9f;
    opacity: 1;
}

.goToTicketButton {
    align-self: center;
    width: 25px;
    height: 25px;
    color: #9f9f9f;
    opacity: 0.9;
    margin-left: 10px;
    cursor: pointer;
}

.goToTicketButton:hover {
    align-self: center;
    width: 26px;
    height: 26px;
    margin-left: 10px;
    color: #9f9f9f;
    opacity: 1;
    cursor: pointer;
}

.issueCommunicationButtonContainerWeb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-top: 26px;
}

.issueCommunicationButtonBadgeWeb {
    align-self: end;
    color: #5bb381;
    padding: 0;
    font-size: small;
    font-weight: bold;
}

.issueCommunicationButtonBadgePlaceholderWeb {
    height: 19px;
}

.issueCommunicationButtonBadgeWeb:hover {
    align-self: end;
    padding: 0;
    font-size: small;
    font-weight: bold;
}

.divider {
    background-color: black;
    opacity: 0.5;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 60%;
    align-self: center;
    height: 1px;
}
