html {
  /* filter: invert(100%); */
  /* font-size: 100%; */
  background-color: var(--bg-color-app);
  color: black;
}

html.dark-mode {
  filter: invert(100%);
  background-color: #09d0ff;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}

:root {
  --feature-badge: #00C9A7;
  --bg-color-app: #24272B;
  --bg-color-landing-page: white;
  --main-text-color-app: #121212;
  --main-text-color-lading-page: white;
  --main-color: #8800E7;
  --hover-color: #8800E7;
  --button-color: linear-gradient(to right, #dc709d, #9c85e6);
  --button-bg-color: linear-gradient(to left, #dc709d, #9c85e6);
}
