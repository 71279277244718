.demoChainContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.demoChainConnectionContainer {
    min-height: 100px;
    padding-left: 20px;
    padding-right: 20px;
    width: 80%;
    background-color: transparent;
    justify-content: start;
    border-top: white;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    margin-top: 10px;
}

.demoChainLinkContainer {

    display: inline-block;
    border-radius: 3px;
    border-color: whitesmoke;
    background-color: transparent;
}