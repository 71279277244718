.invite-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .invite-input {
    border: none;
    transition: border-color 0.3s ease-in-out;
    display: flex;
    height: 36px;
    padding: 8px 16px;
    margin-left: 26px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.40);
    color: white;
  }
  
  .invite-button {
    display: flex;
    cursor: pointer;
    height: 36px;
    padding: 9px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: none;
    color: #FFFFFF;
    background: #3160e1;
  }

  .invite-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .access-level-dropdown {
    height: 36px;
    padding: 10px;
    border-radius: 20px;
    border-color: black;
    font-size: xx-small;
    background-color: whitesmoke;
  }
  
  .invite-input-container {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }
  
  .invite-input-container input {
    border: none;
    border-bottom: 2px
  }  