.projectsMainContainer {
    width: 40%;
    max-height: 70%;
    overflow: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #334756;
    border-radius: 5px;
}

.projectsContentContainer  {
    width: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.buttonArea  {
    display: flex;
    height: 100px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.progressBarArea  {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.projectsContentTitleContainer {
    display: flex;
    margin-left: 20px;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.projectsContainer {
    background-color: #475967;
    border-radius: 0 0 5px 5px;
    padding-bottom: 20px;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.projectsContainerItems {
    border: 1px solid #FFFFFFCC;
    border-right-width: 0;
    border-left-width: 0;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    overflow: auto;
}

.projectItem {
    display: inline-block;
}

