@import "../../../index.css";

@media (min-width: 1024px) {
    .homePageHeaderContainer {
        padding: 40px;
    }
}

@media (max-width: 768px) {
    .homePageHeaderContainer {
        padding: 10px;
    }
}

.homePageHeaderContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    background-color: var(--bg-color-landing-page);
    color: #e9ecef;
}

.left-side-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.homePageStatus {
    background-color: var(--main-color);
    border-radius: 10px;
    border-color: white;
    font-size: small;
    padding: 5px 10px;
    font-weight: normal;
}

.homePageStatus:hover {
    background-color: var(--hover-color);
    border-radius: 10px;
    border-color: white;
    font-size: small;
    padding: 5px 10px;
    font-weight: bold;
}

.homePageOptionText {
    color: var(--main-color);
    margin-right: 15px;
    font-weight: lighter;
}

.homePageOptionText:hover {
    color: var(--hover-color);
    margin-right: 15px;
    font-weight: normal;
}

.homePageOptionTextSelected {
    color: var(--main-color);
    margin-right: 15px;
    font-weight: normal;
}

.homePageOptionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.integrationsMenu {
    color: var(--main-color);
}
