.registrationContainer {
  height: 100vh;
  background-color: var(--bg-color-app);
  display: flex;
  justify-content: center;
  align-items: center;
}

.registrationFieldsContainer{
  width: 80%;
  max-width: 500px;
  background-color: var(--bg-color-app);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.registrationForm {
  width: 100%;
  gap: 20px;
  margin-top: 40px;
  align-items: center;
  align-self: center;
  flex-direction: column;
  display: flex;
}

.registration-sign-in {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.registration-sign-in u {
  cursor: pointer;
}

.registration-sign-in u:hover {
  color: var(--hover-color);
}

.loginField {
  width: 300px;
  color: white;
  border-color: transparent;
  border-radius: 10px;
  background-color: white;
}

.codeField {
  width: 300px;
  /* color: var(--main-color); */
  border-color: transparent;
  border-radius: 10px;
  background-color: var(--main-color);
}

.logo {
  display: block;
  margin: 0 auto 20px;
  width: 200px;
  max-width: 300px;
}