.taskMobileMainContainer {
    align-self: center;
    display: flex;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.taskEstimationContainerMobile {
    width: 60px;
    margin-left: 15px;
    justify-content: start;
    display: flex;
    align-self: center;
    align-items: center;
}

.taskMobileContainer {
    align-self: center;
    display: flex;
    padding: 10px;
    background-color: #334756;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    flex-direction: column;
    justify-content: start;
}

.taskMobileTopContainer {
    width: 80%;
    text-align: left;
    display: flex;
    justify-content: start;
    flex-direction: row;
}

.taskMobileBottomContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.taskMobileBottomContainerLeft {

}

.taskMobileBottomContainerMiddle {

}

.taskMobileBottomContainerRight {

}

