@import "../../index.css";

.videoButtonContainer {
  display: flex;
  align-items: center;
}

.videoButton {
  background-color: white;
  border: 2px solid var(--main-color);
  color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
}

.videoButton:hover {
  background-color: var(--hover-color);
  border: 2px solid var(--hover-color);
  color: white;
}

.videoButtonText {
  font-size: 16px;
  color: var(--main-color);
}

button .MuiSvgIcon-root {
  fill: var(--main-color);
}

.headerText {
  color: var(--main-text-color-app);
  text-shadow: black 1px 1px 2px;
  opacity: 0.8;
  margin: 10px 10px 30px;
  font-style: italic;
  font-weight: bold;
  font-size: 65px;
  width: 100%;
  align-self: center;
  text-align: center;
}


.videoModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.videoModalContent {
  position: relative;
  width: 80%;
  height: 80%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.videoPlayer {
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  .videoButtonContainer {
    flex-direction: column;
    align-items: center;
  }

  .videoButton {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }

  .videoButtonText {
    font-size: 14px;
    text-align: center;
  }

  .videoModalContent {
    width: 90%;
    height: 90%;
  }

  .closeButton {
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    font-size: 16px;
  }
}