.projectSelectorMainContainer {
    width: 50%;
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: #334756;
    border-radius: 5px;
}

.projectSelectorItemsContainer {
    width: 100%;
    height: 300px;
    border: 1px solid #FFFFFFCC;
    border-right-width: 0;
    border-left-width: 0;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    overflow: auto;
}

.projectItem {
    margin: 25px;
    display: inline-block;
}

.projectSelectorTitle {
    margin-bottom: 40px;
    color: white;
    opacity: 0.9;
    font-size: 21px;
}
