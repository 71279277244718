/* Base styling for the blog post */
.blog-post {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Georgia', serif;
    line-height: 1.6;
    color: #333;
}

/* Fullscreen and responsive layout */
body, html {
    width: 100%;
    margin: 0;
    padding: 0;
    background: #f4f4f4;
}

/* Cover image */
.blog-post img:first-of-type {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    margin-bottom: 20px;
}

/* Metadata styling */
.blog-meta {
    font-size: 0.85rem;
    color: #666;
    margin-bottom: 10px;
}

/* Category and tags */
.blog-post h2 {
    color: var(--main-text-color-app);
}

/* Headings within the content */
.blog-post h3 {
    color: #2a2f2a;
}

/* Social media links */
.social-links a {
    text-decoration: none;
    color: var(--main-text-color-app);
    margin-right: 10px;
}

.social-links a:hover {
    text-decoration: underline;
}

.blog-section-container {
    margin-top: 40px;
}

.blog-section {
    margin-top: 20px;
}

.back-button {
    z-index: 10;
    position: absolute;
    top: 100px;
    left: 20px;
    padding: 10px;
    font-size: 1em;
    cursor: pointer;
    color: #333;
    background-color: #f4f4f4;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.back-button:hover {
    background-color: #e2e2e2;
}

.blog-conclusion {
    margin-top: 40px;
}

/* Responsive design */
@media (max-width: 768px) {
    .blog-post {
        padding: 10px;
    }

    .blog-post img:first-of-type {
        max-height: 300px;
    }
}
