.loginPickerMainContainer {
    display: flex;
    background-color: #082032;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.loginPickerContainer {
    display: flex;
    width: 50%;
    background-color: #334756;
    max-width: 500px;
    height: 500px;
    max-height: 500px;
    align-self: center;
    border-radius: 5px;
    flex-direction: row;
    justify-content: space-evenly;
}

.loginPickerRight {
    justify-content: center;
    width: 100%;
    display: flex;
    border-radius: 0 5px 5px 0;
    height: 100%;
    border-color: white;
    border-width: 0 0 0 1px;
    border-style: solid;
}

.loginPickerLeft {
    border-color: white;
    border-width: 0 1px 0 0;
    border-style: solid;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 5px 0 0 5px;
}