/* BlogPostCard.css */
.blog-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
    margin: 20px;
    line-height: 1.6;
    color: #333;
    transition: box-shadow 0.3s ease;
}

.blog-card:hover {
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.title {
    color: #333;
    font-size: 24px;
    margin-bottom: 5px;
}

.metadata, .author, .date-published {
    font-size: 14px;
    color: #666;
    font-style: italic;
}

.category {
    font-size: 16px;
    color: var(--main-color);
    margin: 10px 0;
}

.tags .tag {
    background: #f1f1f1;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 5px;
    font-size: 12px;
}

.section h4 {
    margin-top: 20px;
    color: #333;
    font-size: 20px;
}

.introduction, .conclusion {
    margin-top: 10px;
}

.social-links a {
    margin-right: 10px;
    color: #3b5998;
    text-decoration: none;
}

.social-links a:hover {
    text-decoration: underline;
}

.cover-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
}