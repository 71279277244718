.addProjectsMainContainer {
    width: 500px;
    padding-bottom: 20px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-color-app);
    border-radius: 5px;
}
.addProjectsOptionsContainer {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.addProjectsOption {
    padding: 10px;
    width: 45%;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    border: 1px solid var(--bg-color-app);
}

.addProjectsOption:hover {
    padding: 10px;
    border-radius: 5px;
    justify-content: center;
    border: 2px solid white;
    cursor: pointer;
}

.close-button {
    background: transparent;
    border: none;
    font-size: 15px;
    margin: 15px 0;
    cursor: pointer;
}

.close-button:hover {
    color: white;
}